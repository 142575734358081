
<template>
<div class="box-content">
    <NavBar
  title="公告详情"
  left-text="返回"
  left-arrow
  @click-left="onClickLeft"
  
/>
<!-- @click-right="onClickRight" -->
    <div class="box-title">
     <h5>{{title}}</h5>
      <p>发表时间:{{time}}</p>
    </div>
    <div class="content">
      <p>
        {{content}}
      </p>
    </div>
 </div>
</template>
<script>
import { NavBar } from "vant";
export default {
     name: "NoticeDetails",
     components:{
        NavBar
     },
    data() {
       return {
        title:'',
        time:'',
        content:''
       } 
    },
    created(){
      this.title=this.$route.query.title
      this.time=this.$route.query.time
      this.content=this.$route.query.content
    },
    methods:{
     onClickLeft(){
        this.$router.go(-1)
     },
    }
}
</script>
<style scoped>
.box-title>p{
    font-size: 14px;
    text-align: right;
    width: 95%;
    line-height: 1.1rem;
}
.box-title>h5{
    margin-top: 15px;
}
.content{
    width: 95%;
    margin: 0 auto;
}
.content>p{
    font-size: 15px;
}
</style>