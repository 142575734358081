const companyList = {
  code: 1,
  msg: "",
  time: "1711254964",
  data: {
    error_code: 0,
    reason: "查询成功",
    result: {
      total: 100000,
      data: [
        {
          regNumber: "510100000090276",
          regType: "公司",
          companyName: "中国建筑西南设计研究院有限公司",
          companyType: "法人",
          regMoney: "38331万人民币",
          faRen: "陈勇",
          issueTime: "1991-12-10",
          creditCode: "915101004507202594",
          provinceName: "四川",
          businessStatus: "存续",
        },
        {
          regNumber: "530000000003710",
          regType: "公司",
          companyName: "西南交通建设集团股份有限公司",
          companyType: "法人",
          regMoney: "61022.3582万人民币",
          faRen: "高有德",
          issueTime: "1994-03-16",
          creditCode: "91530000216621068L",
          provinceName: "云南",
          businessStatus: "存续",
        },
        {
          regNumber: "500000000004905",
          regType: "公司",
          companyName: "西南铝业（集团）有限责任公司",
          companyType: "法人",
          regMoney: "355158.460779万人民币",
          faRen: "黎勇",
          issueTime: "1982-02-02",
          creditCode: "915000002028029250",
          provinceName: "重庆",
          businessStatus: "存续",
        },
        {
          regNumber: "510109000233233",
          regType: "公司",
          companyName: "西南水泥有限公司",
          companyType: "法人",
          regMoney: "1167294.019238万人民币",
          faRen: "薄克刚",
          issueTime: "2011-12-12",
          creditCode: "915101005875623516",
          provinceName: "四川",
          businessStatus: "存续",
        },
        {
          regNumber: "520000000091188",
          regType: "公司",
          companyName: "西南能矿集团股份有限公司",
          companyType: "法人",
          regMoney: "487315.17万人民币",
          faRen: "赵震海",
          issueTime: "2012-09-28",
          creditCode: "91520000055016827Y",
          provinceName: "贵州",
          businessStatus: "存续",
        },
        {
          regNumber: "440982000009452",
          regType: "公司",
          companyName: "广东西南建设集团有限公司",
          companyType: "法人",
          regMoney: "22369万人民币",
          faRen: "黄海勇",
          issueTime: "2010-02-21",
          creditCode: "914409825517014365",
          provinceName: "广东",
          businessStatus: "存续",
        },
        {
          regNumber: "510100000042130",
          regType: "公司",
          companyName: "中国电力工程顾问集团西南电力设计院有限公司",
          companyType: "法人",
          regMoney: "100000万人民币",
          faRen: "王强",
          issueTime: "1998-01-16",
          creditCode: "91510100768614747H",
          provinceName: "四川",
          businessStatus: "存续",
        },
        {
          regNumber: "500000000001505",
          regType: "公司",
          companyName: "西南证券股份有限公司",
          companyType: "法人",
          regMoney: "664510.9124万人民币",
          faRen: "吴坚",
          issueTime: "1990-06-07",
          creditCode: "91500000203291872B",
          provinceName: "重庆",
          businessStatus: "存续",
        },
        {
          regNumber: "510100000125996",
          regType: "公司",
          companyName: "中国建筑西南勘察设计研究院有限公司",
          companyType: "法人",
          regMoney: "25130万人民币",
          faRen: "朱文汇",
          issueTime: "1989-12-05",
          creditCode: "915101002019004789",
          provinceName: "四川",
          businessStatus: "存续",
        },
        {
          regNumber: "511602000006385",
          regType: "公司",
          companyName: "西南建工集团有限公司",
          companyType: "法人",
          regMoney: "10058万人民币",
          faRen: "沈鑫",
          issueTime: "1989-09-07",
          creditCode: "915116007118166988",
          provinceName: "四川",
          businessStatus: "存续",
        },
        {
          regNumber: "510000000300508",
          regType: "公司",
          companyName: "中石化西南石油工程有限公司",
          companyType: "法人",
          regMoney: "30000万人民币",
          faRen: "唐世春",
          issueTime: "2012-12-21",
          creditCode: "91510000058247727B",
          provinceName: "四川",
          businessStatus: "存续",
        },
        {
          regNumber: "522328000008040",
          regType: "公司",
          companyName: "贵州西南建筑工程有限公司",
          companyType: "法人",
          regMoney: "10496万人民币",
          faRen: "杨学博",
          issueTime: "2009-03-04",
          creditCode: "915223287411368612",
          provinceName: "贵州",
          businessStatus: "存续",
        },
        {
          regNumber: "510000000043014",
          regType: "公司",
          companyName: "核工业西南建设集团有限公司",
          companyType: "法人",
          regMoney: "30600万人民币",
          faRen: "陈建全",
          issueTime: "1993-01-06",
          creditCode: "9151000071609310XT",
          provinceName: "四川",
          businessStatus: "存续",
        },
        {
          regNumber: "510000000058475",
          regType: "公司",
          companyName: "中国市政工程西南设计研究总院有限公司",
          companyType: "法人",
          regMoney: "52500万人民币",
          faRen: "肖玉芳",
          issueTime: "1994-02-04",
          creditCode: "91510000450722131W",
          provinceName: "四川",
          businessStatus: "存续",
        },
        {
          regNumber: "510000000005451",
          regType: "公司",
          companyName: "核工业西南勘察设计研究院有限公司",
          companyType: "法人",
          regMoney: "16000万人民币",
          faRen: "李辉良",
          issueTime: "2001-05-08",
          creditCode: "91510000727474928K",
          provinceName: "四川",
          businessStatus: "存续",
        },
        {
          regNumber: "510900000126799",
          regType: "公司",
          companyName: "绿地西南建工有限公司",
          companyType: "法人",
          regMoney: "30000万人民币",
          faRen: "高翔",
          issueTime: "2019-07-23",
          creditCode: "91340122MA2TXYTT1N",
          provinceName: "四川",
          businessStatus: "存续",
        },
        {
          regNumber: "520000000023763",
          regType: "公司",
          companyName: "西南能矿建设工程有限公司",
          companyType: "法人",
          regMoney: "36228.15万人民币",
          faRen: "陈恨水",
          issueTime: "1996-05-22",
          creditCode: "91520000214594174P",
          provinceName: "贵州",
          businessStatus: "存续",
        },
        {
          regNumber: "510109000145532",
          regType: "公司",
          companyName: "西南化工研究设计院有限公司",
          companyType: "法人",
          regMoney: "32862.88万人民币",
          faRen: "孙炳",
          issueTime: "1988-01-18",
          creditCode: "91510100450723986B",
          provinceName: "四川",
          businessStatus: "存续",
        },
        {
          regNumber: "510626000011216",
          regType: "公司",
          companyName: "四川西南发展控股集团有限公司",
          companyType: "法人",
          regMoney: "50000万人民币",
          faRen: "兰军",
          issueTime: "2012-03-29",
          creditCode: "91510626592783220Q",
          provinceName: "四川",
          businessStatus: "存续",
        },
        {
          regNumber: "500109000039415",
          regType: "公司",
          companyName: "西南绿城房地产开发有限公司",
          companyType: "法人",
          regMoney: "10000万人民币",
          faRen: "叶青",
          issueTime: "2011-12-22",
          creditCode: "915001095880393946",
          provinceName: "重庆",
          businessStatus: "存续",
        },
      ],
    },
    ordersign: "20240324612502923846520832",
  },
};

export default companyList;