<template>
  <div>
    <div class="order-list" v-if="orderList.length > 0">
      <ul>
        <li v-for="item in orderList" class="order-litem">
          <div class="order-litem-title flex">
            <div>订单号：{{ item.info7 }}</div>
            <div class="pay-status">待支付</div>
          </div>
          <div class="order-info">
            <div>
              <span class="order-info-label">认证公司：</span>
              <span class="order-info-value">{{ item.info1 }}</span>
            </div>
            <div>
              <span class="order-info-label">社会信用代码：</span>
              <span class="order-info-value">{{ item.info2 }}</span>
            </div>
            <div>
              <span class="order-info-label">法人代表：</span>
              <span class="order-info-value">{{ item.info3 }}</span>
            </div>
            <div>
              <span class="order-info-label">身份证号码：</span>
              <span class="order-info-value">{{ item.info4 }}</span>
            </div>
            <div>
              <span class="order-info-label">服务项目：</span>
              <span class="order-info-value">{{ item.info5}}</span>
            </div>
            <div>
              <span class="order-info-label">检验说明：</span>
              <span class="order-info-value">{{ item.info6 }}</span>
            </div>
          </div>
          <div class="order-pay">
            <div>支付： <span class="money">￥{{ item.info8 }}</span></div>
            <div class="pay-button" @click="reward_pay">立即支付</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="no-order-warp" v-if="orderList.length == 0">
      <Icon name="orders-o" class="no-order-icon" />
      <p class="no-order-msg">您还没有订单信息</p>
    </div>
  </div>
</template>

<script>
import { Icon } from "vant";
import wx from "weixin-js-sdk"
import axios from 'axios'
export default {
  name: "Order",
  components: {
    Icon,
  },
  data() {
    return {
      orderList: [
        {
          info1: "是的地方",
          info2: "2011-11-11 11:11",
          info3: "水多的",
          info4: "2011-11-11 11:11",
          info5: "的撒",
          info6: "大撒大撒",
          info7: "123",
          info8: "555",
        },
        {
          info1: "是的地方",
          info2: "2011-11-11 11:11",
          info3: "水多的",
          info4: "2011-11-11 11:11",
          info5: "的撒",
          info6: "大撒大撒",
          info7: "321",
          info8: "6666",
        },
      ],
      value: "",
    };
  },
  methods:{
    reward_pay(){
       axios({
        mounted:'Post',
        url:'http://test.core.zaillusion.cn/pay',
        data:{}
        // data:{
        //   code:'1111111111'
        // }
      }).then((res)=>{
        console.log('res==>>',res);
        this.pay(res.data)
      })
    },
    pay(data){
      wx.config({
	debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false, 
	appId: data.appId, // 必填，公众号的唯一标识 
	timestamp:data.timeStamp, // 必填，生成签名的时间戳     
	nonceStr:data.nonceStr, // 必填，生成签名的随机串 
	signature:data.paySign, // 必填，签名 
	jsApiList: ['chooseWXPay', 'checkJsApi'] // 必填，需要使用的JS接口列表 
})
wx.ready(() => {
		wx.chooseWXPay({
			timestamp:data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符     
			nonceStr:data.nonceStr, // 支付签名随机串，不长于 32 位         
			package:data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）     
			signType: 'SHA1', // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'     
			paySign:data.paySign, // 支付签名     
			success: function(payRes) { 
			// 支付成功后的回调函数       
       alert('支付成功')
			},
			cancel: function(payRes) {
				//  this.$message.error('取消支付')
         alert('取消支付')
        console.log('取消支付');

			},
			fail: function(payRes) {
				// this.$message.error('支付失败')
        alert('支付失败')
        console.log('支付失败');
			}
		})
})
    }
  },
};
</script>

<style scoped>
.no-order-warp {
  position: absolute;
  top: 40%;
  transform: translateY(-40%);
  text-align: center;
  width: 100%;
}
.no-order-icon {
  font-size: 90px;
  color: rgb(83, 97, 244);
}
.no-order-msg {
  color: rgb(83, 97, 244);
  font-size: 14px;
}
.order-list {
  padding: 20px 16px;
}
.order-litem {
  background-color: #fff;
  border-radius: 6px;
  font-size: 14px;
  padding: 10px;
  margin-bottom: 20px;
}
.order-litem-title {
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 15px 0;
  border-bottom: 1px solid #d3d3d3;
}
.pay-status {
  padding: 2px 6px;
  color: rgb(83, 97, 244);
  border: 1px solid rgb(83, 97, 244);
  border-radius: 6px;
}
.order-info {
  padding: 15px 0;
  border-bottom: 1px solid #d3d3d3;
  text-align: left;
}
.order-info > div {
  margin: 5px 0;
}
.order-info-label {
  color: #999;
}
.order-info-value {
  color: #000;
}
.order-pay {
  text-align: right;
  font-size: 15px;
  color: #999;
  margin-top: 15px;
  overflow: hidden;
}
.money {
  color: red;
}
.pay-button {
  width: 80px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 10px;
  background-color: rgb(83, 97, 244);
  border-radius: 20px;
  color: #fff;
  float: right;
  margin-top: 10px;
}
</style>
