// 引入vue框架
import Vue from "vue";
// 引入vue-router路由依赖
import Router from "vue-router";

import Home from "@/views/Home.vue";
import Order from "@/views/Order.vue";
import Notice from "@/views/Notice.vue";
import NoticeDetails from "@/views/NoticeDetails.vue";
import CompanyList from "@/views/CompanyList.vue";
import CompanyDetail from "@/views/CompanyDetail.vue";
// 使用路由依赖
Vue.use(Router);

// 定义路由配置
export default new Router({
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/Order",
      name: "Order",
      component: Order,
    },
    {
      path: "/Notice",
      name: "Notice",
      component: Notice,
    },
    {
      path: "/NoticeDetails",
      name: "NoticeDetails",
      component: NoticeDetails,
    },
    {
      path: "/CompanyList",
      name: "CompanyList",
      component: CompanyList,
    },
    {
      path: "/CompanyDetail",
      name: "CompanyDetail",
      component: CompanyDetail,
    },
  ],
});
