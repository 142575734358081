<template>
  <div>
    <div class="order-list">
      <ul>
        <li
          v-for="item in companyList.data.result.data"
          :key="item.regNumber"
          class="company-item"
          @click="goDetail(item)"
        >
          <div class="flex">
            <span class="company-name">{{ item.companyName }}</span>
            <div class="authentication-btn">立即认证</div>
          </div>
          <div class="flex">
            <div class="company-info-item">
              <p class="company-info-label">法人代表：</p>
              <p class="company-info-text">{{ item.provinceName }}</p>
            </div>
            <div class="company-info-item">
              <p class="company-info-label">登记状态</p>
              <p class="company-info-text">{{ item.businessStatus }}</p>
            </div>
            <div class="company-info-item">
              <p class="company-info-label">成立时间</p>
              <p class="company-info-text">{{ item.issueTime }}</p>
            </div>
          </div>
          <div>
            统一信用代码: <span class="creditCode"> {{ item.creditCode }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Icon } from "vant";
import companyList from "../mock/companyList";
export default {
  name: "Order",
  components: {
    Icon,
  },
  data() {
    return {
      companyList: companyList,
      value: "",
    };
  },
  mounted() {
    this.getCompanyList();
  },
  methods: {
    getCompanyList() {
      const timespan = Math.floor(Date.now() / 1000).toString();
      const Appkey = "4ff62848a26241be9cece91416097010";
      const SecretKey = "43B3CF87C5A4C171CFC156052CE31614";
      const sign = this.$md5(Appkey + timespan + SecretKey).toUpperCase();
      const headers = {
        Token: sign,
        Timespan: timespan,
        "Content-Type": "application/json",
      };
      const params = {
        key: Appkey,
        searchKey: this.$route.query.name,
        pageSize: 20,
        pageIndex: 1,
      };
      this.$axios
        .get("/api", {
          headers: headers,
          params: params,
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    goDetail(item) {
      this.$router.push({
        name: "CompanyDetail",
        query: item,
      });
    },
  },
};
</script>

<style scoped>
.company-item {
  background-color: #fff;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 12px;
  text-align: left;
  .company-name {
    color: #000;
    font-size: 17px;
    font-weight: 500;
    /* font-size: 16px;
    font-weight: bold; */
  }
  .authentication-btn {
    background-color: #4395ff;
    color: #fff;
    white-space: nowrap;
    padding: 6px 16px;
    border-radius: 6px;
  }
  .company-info-item {
    text-align: center;
    .company-info-label {
      color: #d3d3d3;
    }
    .company-info-text {
      color: #000;
      margin: 6px 0 3px;
    }
  }
}
.company-item > div {
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;
  padding: 8px 15px;
}
.creditCode {
  color: #000;
  font-size: 16px;
}
</style>
