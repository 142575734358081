<!--
 * @Title: 标题
 * @Description: 描述
 * @Author: gzy
 * @Date: 2024-03-23 17:31:20
 * @LastEditors: gzy
 * @LastEditTime: 2024-04-06 13:41:01
 * @FilePath: /src/views/Home.vue
-->
<template>
  <div>
    <div class="home-bg-warp">
      <div class="search">
        <div class="search-title flex">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAPCAQAAACVKo38AAAAAXNSR0IArs4c6QAAATJJREFUGNNjYICCmawfKn99+VC5io0BGZwUfpfx8/bfn1/X/f316+773EOiYOGfHd+v/v3959eXpfc1GBjua3xZ8ufn3z/fbvzsZPi//+eJt4knxBFmnBF5E/vzxP/9QKmPvciGNzBdk2Rg+NgLlvrUiyzxaeGP2/M5PqFLNTB9Wfz394dQBga4VCjzh6kvXUOZPy/9++ttMEjRF5DUn32fZ81k/bHx77evu/7+ehMI0f9l3p89DJ9nf7vAwLCK7dumvz9fBsCM/n7z82SG517//j+wBEk+soVJvHD+//+xPQMD4/eTv66e4Ue48qrQzzvf9oCZt5R+v/xx6bYWROK29s8rvx7dlIaqe6L248rfn1/mvPD/Mu/vrx/n7skjhcE29g8lPx78///j/pucmawQMQBCM7I5CWr4IgAAAABJRU5ErkJggg=="
            alt=""
          />
          <span class="ic-title">企业认证</span>
        </div>
        <div class="search-input">
          <form action="/">
            <Search
              v-model="companyName"
              shape="round"
              show-action
              placeholder="搜索公司名称去认证"
              @search="onSearch"
            >
              <template #action>
                <div @click="onSearch">搜索</div>
              </template>
            </Search>
          </form>
        </div>
      </div>
    </div>
    <div class="news">
      <div class="flex news-title">
        <div class="latest">最新公告</div>
        <div class="more">></div>
      </div>
      <div class="news-content">
        <ul>
          <li
            v-for="item in news"
            :key="item.time"
            class="news-item"
            @click="handleRout(item)"
          >
            <div class="news-item-time">{{ item.time }}</div>
            <div class="news-item-title">{{ item.title }}</div>
            <div class="news-item-content">{{ item.content }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="company-num">已认证<span>54620</span>家企业</div>
  </div>
</template>

<script>
import { Search } from "vant";
import axios from 'axios'
export default {
  name: "Home",
  components: {
    Search,
  },
  data() {
    return {
      news: [
        {
          title: "111",
          time: "2011-11-11 11:11",
          content:
            "公司被列入经营异常将面临以下后果:1.信用受损:被列入经营异常的公司将被公示，这会导致公司的信誉和声誉受到损害，影响与合作伙伴、客户的信任关系，可能导致业务萎缩。2.行政处罚:相关部门会对经营异常的公司进行调查，并可能对其进行行政处罚，例如罚款。3.日常经营活动受限:公司被列入经营异常后，可能面临银行账户被冻结、无法办理各类行政许可 被限制参加政府采购活动等情况，日常经营活动受到极大限制。4.法律诉讼:若公司因经营异常而无法履行合同或债务，可能引发法律诉讼，导致经济损失。5.影响公司上市:若公司有上市计划，被列入经营异常可能导致上市进程受阻或推迟，甚至可能导致无法上市。6.被列入黑名单:若公司被列入经营异常，将可能被列入黑名单，与其他信用不良的企业一同公示，影响企业经营和发展。因此，公司被列入经营异常将带来一系列负面影响，需引起高度重视，积极采取措施解决问题，保持良好的企业信用和合规经营。",
        },
        {
          title: "222",
          time: "2011-11-11 11:12",
          content:
            "公司被列入经营异常将面临以下后果:1.信用受损:被列入经营异常的公司将被公示，这会导致公司的信誉和声誉受到损害，影响与合作伙伴、客户的信任关系，可能导致业务萎缩。2.行政处罚:相关部门会对经营异常的公司进行调查，并可能对其进行行政处罚，例如罚款。3.日常经营活动受限:公司被列入经营异常后，可能面临银行账户被冻结、无法办理各类行政许可 被限制参加政府采购活动等情况，日常经营活动受到极大限制。4.法律诉讼:若公司因经营异常而无法履行合同或债务，可能引发法律诉讼，导致经济损失。5.影响公司上市:若公司有上市计划，被列入经营异常可能导致上市进程受阻或推迟，甚至可能导致无法上市。6.被列入黑名单:若公司被列入经营异常，将可能被列入黑名单，与其他信用不良的企业一同公示，影响企业经营和发展。因此，公司被列入经营异常将带来一系列负面影响，需引起高度重视，积极采取措施解决问题，保持良好的企业信用和合规经营。",
        },
        {
          title: "333",
          time: "2011-11-11 11:13",
          content:
            "公司被列入经营异常将面临以下后果:1.信用受损:被列入经营异常的公司将被公示，这会导致公司的信誉和声誉受到损害，影响与合作伙伴、客户的信任关系，可能导致业务萎缩。2.行政处罚:相关部门会对经营异常的公司进行调查，并可能对其进行行政处罚，例如罚款。3.日常经营活动受限:公司被列入经营异常后，可能面临银行账户被冻结、无法办理各类行政许可 被限制参加政府采购活动等情况，日常经营活动受到极大限制。4.法律诉讼:若公司因经营异常而无法履行合同或债务，可能引发法律诉讼，导致经济损失。5.影响公司上市:若公司有上市计划，被列入经营异常可能导致上市进程受阻或推迟，甚至可能导致无法上市。6.被列入黑名单:若公司被列入经营异常，将可能被列入黑名单，与其他信用不良的企业一同公示，影响企业经营和发展。因此，公司被列入经营异常将带来一系列负面影响，需引起高度重视，积极采取措施解决问题，保持良好的企业信用和合规经营。",
        },
      ],
      value: "",
      companyName: "",
      userInfo: {
        data: {},
      },
      appId:"wxc388ce289c832e78",
      appsecret:"0b59bbf28c51dd77832092e82f2d2646",
      code: "",
    };
  },
  mounted() {
    // this.getLogin()
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("micromessenger") !== -1) {
      if (!localStorage.getItem("code")) {
        this.getWXRight();
      }else{
        this.gelist()
      }
    } else {
      console.log("当前页面不在微信中打开");
    }
  },
  methods: {
    getWXRight() {
      // getUsernfo
      // 非静默授权，第一次有弹框
      var local = "http://gongkai.zaillusion.cn/index.html"; // 获取页面url
      console.log('local',local);
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
        this.appId
      }&redirect_uri=${encodeURIComponent(
        local
      )}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
      const url = new URL(window.location.href);
      console.log(url);
      // 创建URLSearchParams对象
      const params = new URLSearchParams(url.search);
      // 从URLSearchParams对象中获取code参数
      this.code = params.get("code");
      console.log(this.code);
      console.log('code',this.code);
      // this.getLogin(this.code)
      // console.log();
      localStorage.setItem("code", this.code);
      console.log('localStorage.getItem("code")',localStorage.getItem("code"));
    },
    onSearch() {
      const newUrl = `https://api.weixin.qq.com/sns/oauth2/access_token?appid=${appId}&secret=${appsecret}&code=${code}&grant_type=authorization_code`;
      this.$axios
        .get(newUrl)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });

      this.$router.push({
        name: "CompanyList",
        query: {
          name: this.companyName,
        },
      });
    },
    gelist(){
      console.log('localStorage.getItem("code")',localStorage.getItem("code"));
    },
    getLogin(val){
      // let url ='/api/getUsernfo'
      axios({
        mounted:'get',
        url:'/apy/getAccessToken',
        // data:{
        //   code:'1111111111'
        // }
      }).then((res)=>{
        console.log('res==>>',res);
      })
      // let data={
      //   code:val
      // }
      // console.log('val==code===>>>',val);
      // this.$axios
      //   .get(url,data:data)
      //   .then((response) => {
      //     console.log(response.data);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    },
    handleRout(val) {
      this.$router.push({
        name: "NoticeDetails",
        query: {
          title: val.title,
          content: val.content,
          time: val.time,
        },
      });
    },
  },
};
</script>

<style scoped>
.home-bg-warp {
  width: 100%;
  height: 160px;
  background: url(../assets/images/home-bg2.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.news {
  margin-top: 10px;
  background-color: #fff;
}

.news .news-title {
  padding: 10px 15px;
  font-size: 15px;
  color: #303133;
  display: flex; /* 假设你希望实现justify-content和align-items的效果 */
  justify-content: space-between;
  align-items: center;
}

.news .latest {
  border-left: 5px solid #5264d9;
  padding-left: 10px;
}

.news .more {
  font-size: 14px;
  color: #606266;
}

.news .news-content,
.news .news-content ul {
  background-color: rgb(243, 244, 246);
}

.news .news-item {
  font-size: 15px;
  padding: 15px;
  border-top: 1px solid rgb(243, 244, 246);
  background-color: #fff;
  text-align: left;
  margin-bottom: 5px;
}

.news .news-item .news-item-time {
  font-size: 12px;
  color: rgb(22, 142, 206);
  margin-bottom: 10px;
  text-align: justify;
}

.news .news-item .news-item-title {
  font-size: 16px;
  border-bottom: 1px solid rgb(238, 234, 234);
  padding-bottom: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news .news-item .news-item-content {
  color: rgb(143, 143, 143);
  font-size: 14px;
  text-align: justify;
  max-height: 88px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.news .news-item .label {
  font-size: 15px;
  width: 36px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  border: 1px solid #3c9cff;
  border-radius: 6px;
  color: #3c9cff;
  font-size: 12px;
}

.news .news-item .inner {
  font-size: 15px;
}

.news .news-item .time {
  font-size: 14px;
}

.search {
  padding-top: 35px;
}

.search .search-title {
  padding: 15px;
  display: flex; /* 假设你希望实现align-items的效果 */
  align-items: center;
}

.search .search-title .ic-title {
  margin-left: 10px;
}
.search .search-input {
  padding-right: 10px;
  position: relative;
}
.search .van-cell {
  padding: 10px 8px 10px 0;
}
.search .van-search {
  background-color: unset;
  padding: 0 12px;
}
.search .van-search__action {
  position: absolute;
  right: 10px;
  height: 44px;
  line-height: 44px;
  background-color: #1989fa;
  border-radius: 0px 33px 33px 0px;
  width: 104px;
  font-size: 14px;
  color: rgb(255, 255, 255);
  text-align: center;
  z-index: 999;
}
.company-num {
  padding: 20px 0;
  font-size: 15px;
}

.company-num span {
  color: #3c9cff;
}
</style>
