<template>
  <div id="app">
    <router-view class="warp-main" />
    <Tabbar v-model="active" :fixed="false">
      <Tabbar-item icon="wap-home-o" to="/">首頁</Tabbar-item>
      <Tabbar-item icon="coupon-o" to="/Order">訂單</Tabbar-item>
    </Tabbar>
  </div>
</template>
<script>
import { Button, Tabbar, TabbarItem } from "vant";
export default {
  name: "App",
  components: {
    [Button.name]: Button,
    Tabbar,
    TabbarItem,
  },
  data() {
    return {
      active: 0,
    };
  },
};
</script>

<style>
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.warp-main {
  flex: 1;
  background-color: rgb(243, 244, 246);
  height: 200px;
  overflow: auto;
}
</style>
