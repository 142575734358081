import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);

// 调用微信内置js对象 WeixinJSBridge 发起支付
function onBridgeReady(params, callback) {
  WeixinJSBridge.invoke(
    "getBrandWCPayRequest",
    {
      appId: params.appId, //公众号名称，由商户传入
      timeStamp: params.timeStamp, //时间戳，自1970年以来的秒数
      nonceStr: params.nonceStr, //随机串
      package: params.package,
      signType: params.signType, //微信签名方式：
      paySign: params.paySign, //微信签名
    },
    function (res) {
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        // 添加支付成功回调，在组件调用之后根据需求修改js逻辑
        callback && callback();
      } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
        Toast("已取消支付");
      }
    }
  );
}

export default {
  /* 微信支付 */
  creatWxPay: function (options, callback) {
    this.$axios({
      url: api.requestApi.video.rewardpay,
      data: {
        id: options.id,
        money: options.money,
      },
    })
      .then((res) => {
        if (res.error === 0) {
          if (res.wechat.success) {
            if (typeof WeixinJSBridge == "undefined") {
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  function () {
                    onBridgeReady(res.wechat, callback);
                  },
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", function () {
                  onBridgeReady(res.wechat, callback);
                });
                document.attachEvent("onWeixinJSBridgeReady", function () {
                  onBridgeReady(res.wechat, callback);
                });
              }
            } else {
              onBridgeReady(res.wechat, callback);
            }
          }
        }
      })
      .catch((err) => {
        Toast("系统异常！");
      });
  },
};
