<template>
  <div class="detail-main">
    <div class="detail-main-bg">
      <div class="company-info">
        <div class="companyName">{{ companyName }}</div>
        <div class="regNumber">统一信用代码：{{ regNumber }}</div>
        <div class="faRen">经营者：{{ faRen }}</div>
      </div>
    </div>
    <div class="price">
      <div class="slide">
        <div @click="chhangeSlide(1)" :class="priceType == 1 ? 'chosed' : ''">
          <!-- 年报补录 -->
          执照年报
        </div>
        <!-- <div @click="chhangeSlide(2)" :class="priceType == 2 ? 'chosed' : ''">
          个体注销+年报
        </div> -->
        <div @click="chhangeSlide(3)" :class="priceType == 3 ? 'chosed' : ''">
          <!-- 公司执照注销 -->
          执照作废
        </div>
        <!-- <div @click="chhangeSlide(4)" :class="priceType == 4 ? 'chosed' : ''">
          个体
        </div> -->
      </div>
      <div class="price-list">
        <div class="price-label">服务项目：</div>
        <div class="price-value title">{{ project }}</div>
      </div>
      <div class="price-list">
        <div class="price-label">检验费用：</div>
        <div class="price-value amount">￥{{ price }}</div>
      </div>
      <div class="price-list">
        <div class="price-label">检验说明：</div>
        <div class="price-value">{{ desc }}</div>
      </div>
    </div>
    <div class="payment">
      <div class="contact">
        <Icon name="service" /><a href="tel:1234567890">联系客服</a>
      </div>
      <div class="pay-now" @click="reward_pay">立即支付</div>
    </div>
  </div>
</template>

<script>
import { Icon } from "vant";
export default {
  name: "Notice",
  components: {
    Icon,
  },
  data() {
    return {
      companyName: "",
      time: "",
      faRen: "",
      project: "年报补录",
      price: "358",
      desc: "年报申报不通过全额退费",
      projectList: {
        1: "年报补录",
        2: "个体注销+年报补录",
        3: "公司执照注销",
        4: "个体",
      },
      priceList: {
        1: "358",
        2: "798",
        3: "1488",
        4: "458",
      },
      descList: {
        1: "年报申报不通过全额退费",
        2: "个体执照注销不成功全额退费",
        3: "公司营业执照注销不成功全额退费",
        4: "个体执照注销不成功全额退费",
      },
      priceType: 1,
    };
  },
  created() {
    console.log(this.$route.query);
    this.companyName = this.$route.query.companyName;
    this.regNumber = this.$route.query.regNumber;
    this.faRen = this.$route.query.faRen;
  },
  methods: {
    chhangeSlide(type) {
      this.priceType = type;
      this.project = this.projectList[type];
      this.price = this.priceList[type];
      this.desc = this.descList[type];
    },
    // wxPay() {
    //   wx.config({
    //     debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
    //     appId: res.data.appId, // 必填，公众号的唯一标识
    //     timestamp: res.data.timeStamp, // 必填，生成签名的时间戳
    //     nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
    //     signature: res.data.paySign, // 必填，签名
    //     jsApiList: ["chooseWXPay", "checkJsApi"], // 必填，需要使用的JS接口列表
    //   });
    //   wx.ready(() => {
    //     // wx.checkJsApi({
    //     //   jsApiList: ['chooseWXPay'],
    //     //   success:function(res){},
    //     //   fail:function(res){}})
    //     wx.chooseWXPay({
    //       timestamp: res.data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
    //       nonceStr: res.data.nonceStr, // 支付签名随机串，不长于 32 位
    //       package: res.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
    //       signType: "SHA1", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
    //       paySign: res.data.paySign, // 支付签名
    //       success: function (payRes) {
    //         // 支付成功后的回调函数
    //       },
    //       cancel: function (payRes) {
    //         // that.$message.error('取消支付')
    //       },
    //       fail: function (payRes) {
    //         // that.$message.error('支付失败')
    //       },
    //     });
    //   });
    // },
    reward_pay() {
      this.wx.wxPay(
        {
          id:'oWsjL6PltKVcH3hapTRLtnejWNIg',
          money: 0.01,
        },
        () => {
          // 成功回调
          this.$toast.success("支付成功！");
          setTimeout(() => {
            this.hide();
            this.callBack && this.callBack();
          }, 2000);
        }
      );
    },
  },
};
</script>

<style scoped>
.detail-main {
  background-color: rgb(65, 147, 247);
  position: relative;
  padding: 15px;
}
.detail-main-bg {
  width: 358px;
  height: 1071px;
  border-radius: 0px;
  overflow: visible;
  background-color: transparent;
  background: url(../assets/images/company-detail-bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  .company-info {
    font-size: 15px;
    height: 78px;
    position: absolute;
    top: 41px;
    left: 41px;
    text-align: left;
    div {
      margin-bottom: 10px;
    }
  }
}
.price {
  font-size: 15px;
  margin-top: 15px;
  background-color: #fff;
  padding: 20px;

  .slide {
    background-color: #eeeeef;
    padding: 10px 3px;
    border-radius: 3px;
    align-items: stretch;
    display: flex;
    > div {
      width: 25%;
      font-size: 12px;
      transition: all 0.5s;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .chosed {
      background-color: #fff;
      font-weight: bold;
      font-size: 12px;
      color: rgb(60, 156, 255);
    }
  }
}
.price-list {
  display: flex;
  padding-top: 20px;
  .price-label {
    white-space: nowrap;
  }
  .title {
    font-weight: bold;
    overflow-wrap: normal;
    font-size: 16px;
    color: rgb(48, 49, 51);
  }
  .amount {
    font-weight: bold;
    overflow-wrap: normal;
    font-size: 16px;
    color: rgb(255, 0, 0);
  }
}
.payment {
  margin-top: 20px;
  height: 62px;
  padding: 0 20px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .contact {
    display: flex;
    align-items: center;
    overflow-wrap: normal;
    font-size: 16px;
    color: rgb(76, 91, 250);
    .van-icon {
      font-size: 30px;
      margin-right: 8px;
    }
  }
  .pay-now {
    color: #fff;
    background-color: #3c9cff;
    border-color: #3c9cff;
    border-width: 1px;
    border-style: solid;
    height: 33px;
    line-height: 33px;
    padding: 0 8px;
    border-radius: 20px;
    text-align: center;
    font-size: 12px;
  }
}
</style>
