import Vue from 'vue'
import App from './App.vue'
import 'amfe-flexible'
import './assets/css/common.css'
import 'vant/lib/index.css';
import router from './router/router';
import axios from 'axios'//引入axios
import md5 from "js-md5";

import wxJSSDK from './assets/js/wx-js-sdk'
const wxApi = {
    wxPay: wxJSSDK.creatWxPay // 微信支付
}
Vue.prototype.wx = wxApi;
Vue.prototype.$md5 = md5;
Vue.prototype.$axios = axios;//把axios挂载到vue上
// 定义实例
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})